import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

function PrivacyPolicy() {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          Effective Date: 03 September, 2024
        </Typography>
        <Box mt={3}>
          <Typography variant="body1" paragraph>
            <strong>1. Introduction</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            Welcome to Ayiro AI ("we," "our," "us"). We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you use our app, which includes using Google and Facebook sign-in services. By using our app, you agree to the terms of this Privacy Policy.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>2. Information We Collect</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Personal Information:</strong> When you sign in using Google or Facebook, we collect non-sensitive personal information such as your name and email address. This information is necessary for account creation and user identification.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Voice Recordings:</strong> With your explicit consent, we may ask you to record specific text in your voice in Indian languages. These recordings are used for internal research and development purposes and are not shared with third parties.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>3. How We Use Your Information</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Personal Information:</strong> Your name and email address are used to manage your account, provide you with access to the app's features, and communicate with you.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Voice Recordings:</strong> The voice recordings you provide are stored securely and are used solely for internal research purposes to improve our services and products. These recordings are not shared with any third parties.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>4. Data Storage and Security</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            We take your privacy and data security seriously. Your personal information and voice recordings are stored in secure storage environments protected by appropriate technical and organizational measures to prevent unauthorized access, disclosure, or loss. We regularly review our security practices to ensure they remain effective.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>5. Third-Party Services</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            We use third-party services like Google and Facebook for sign-in purposes. These services may collect additional data according to their privacy policies. We do not share your personal information or voice recordings with any third-party services beyond what is necessary for the functioning of the app.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>6. Your Consent</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            By using our app, you consent to the collection and use of your personal information and voice recordings as described in this Privacy Policy. You may withdraw your consent for voice recordings at any time by contacting us at [Contact Information].
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>7. Your Rights</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            You have the right to access, update, or delete your personal information at any time. You can do this by contacting us at <Link href="mailto:support@ayiro.ai">support@ayiro.ai</Link>. We will respond to your request within a reasonable time.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>8. Changes to This Privacy Policy</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>9. Contact Us</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            If you have any questions or concerns about this Privacy Policy, please contact us at <Link href="mailto:support@ayiro.ai">support@ayiro.ai</Link> or +91-8867439696.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default PrivacyPolicy;
