import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

function TermsOfUse() {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms of Use
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          Effective Date: 03 September, 2024
        </Typography>
        <Box mt={3}>
          <Typography variant="body1" paragraph>
            <strong>1. Acceptance of Terms</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            By accessing and using ayiro.ai and it's sub-domains ("the app"), you agree to comply with and be bound by these Terms of Use. If you do not agree with these terms, please do not use the app.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>2. Account Registration</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            To access certain features of the app, you may be required to sign in using Google or Facebook. You agree to provide accurate and complete information during the registration process and to update such information to keep it accurate and complete.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>3. Use of the App</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            You agree to use the app for lawful purposes only. You are responsible for all activities that occur under your account. You agree not to use the app to:
          </Typography>
          <Typography variant="body2" component="ul" paragraph>
            <li>Violate any local, state, national, or international law.</li>
            <li>Infringe on the rights of others, including intellectual property rights.</li>
            <li>Engage in any activity that could harm, disrupt, or impair the app's operation.</li>
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>4. Voice Recording Consent</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            By providing your consent, you agree to participate in voice recordings as requested by the app. These recordings will be used for internal research purposes only and will not be shared with third parties. You may withdraw your consent at any time by contacting us at  <Link href="mailto:support@ayiro.ai">support@ayiro.ai</Link>.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>5. Intellectual Property</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            All content on the app, including text, graphics, logos, and software, is the property of Ayiro Ai or its licensors and is protected by intellectual property laws. You may not use, reproduce, or distribute any content without our prior written permission.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>6. Data Privacy</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            Our collection and use of your personal information, including your name, email address, and voice recordings, are governed by our Privacy Policy. By using the app, you consent to our data practices as outlined in the Privacy Policy.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>7. Limitation of Liability</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            To the maximum extent permitted by law, Ayiro AI shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
          </Typography>
          <Typography variant="body2" component="ul" paragraph>
            <li>Your use of or inability to use the app.</li>
            <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>8. Termination</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            We reserve the right to suspend or terminate your access to the app at any time, with or without cause, and without notice.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>9. Governing Law</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            These Terms of Use are governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Odisha.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>10. Changes to These Terms</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            We may modify these Terms of Use from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the app after any changes constitute your acceptance of the new terms.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>11. Contact Us</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            If you have any questions or concerns about these Terms of Use, please contact us at  <Link href="mailto:support@ayiro.ai">support@ayiro.ai</Link>.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default TermsOfUse;
