import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Typography, Box } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#A38989',
    },
  },
  typography: {
    fontFamily: 'Dosis, Arial, sans-serif', // Dosis-Regular font
  },
});

function App() {
  const [showBoxes, setShowBoxes] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBoxes(false);
    }, 5000); // in milliseconds

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          background: 'linear-gradient(135deg, #FFFFFF 30%, #DDCBCB 100%)',
        }}
      >
        <Box
          display="flex"
          width="100%"
          height="30%" // Occupy the top 30% of the page
          sx={{ justifyContent: 'space-between', alignItems: 'center' }} // Align items left and right
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            sx={{ width: '50%' }}
          >
            <img
              src="/ayiro_transparent.png"
              alt="Ayiro Transparent Logo"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center" // Center-align text vertically relative to logo
            sx={{ width: '30%', pr: 4, mt: 10 }} // Added marginTop to move the text down
          >
            <Typography variant="h6" align="center" sx={{ color: '#855555' }}>
              Bridging the AI gap for Indian Languages
            </Typography>
          </Box>
        </Box>

        <Box
          width="100%"
          height="45%" // Occupy the middle section
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Distribute boxes evenly
            alignItems: 'center',
            borderRadius: '20px',
            p: 4,
            mt: 2,
          }}
        >
          <Box
            width="20%" // Left box
            sx={{
              padding: 4,
              borderRadius: '10px',
              textAlign: 'center',
              opacity: showBoxes ? 1 : 0,
              transition: 'opacity 1s ease',
            }}
          >
            <a href="#" style={{ textDecoration: 'none', color: '#855555' }}>
              <Typography variant="h5" align="center">
                Vidhi
              </Typography>
              <Typography variant="body2" align="center">
                Coming Soon
              </Typography>
            </a>
          </Box>

          <Box
            width="20%" // Middle box
            sx={{
              padding: 4,
              borderRadius: '10px',
              textAlign: 'center',
              transform: showBoxes ? 'scale(1)' : 'scale(1.3)',
              transition: 'transform 1s ease',
            }}
          >
            <a
              href="https://dhwani.ayiro.ai"
              style={{ textDecoration: 'none', color: '#855555' }}
            >
              <Typography variant="h5" align="center">
                Dhwani
              </Typography>
              <Typography variant="body2" align="center">
                Voice Corpus
              </Typography>
            </a>
          </Box>

          <Box
            width="20%" // Right box
            sx={{
              padding: 4,
              borderRadius: '10px',
              textAlign: 'center',
              opacity: showBoxes ? 1 : 0,
              transition: 'opacity 1s ease',
            }}
          >
            <a href="#" style={{ textDecoration: 'none', color: '#855555' }}>
              <Typography variant="h5" align="center">
                Shrusti
              </Typography>
              <Typography variant="body2" align="center">
                Coming Soon
              </Typography>
            </a>
          </Box>
        </Box>

        <Box
          width="100%"
          sx={{
            backgroundColor: '#F5F5F5',
            p: 3,
            borderRadius: '5px',
            textAlign: 'center',
            mt: 3,
          }}
        >
          <Box sx={{ mt: 2 }}>
            <a
              href="mailto:support@ayiro.ai"
              style={{ textDecoration: 'none', color: '#855555' }}
            >
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Contact Us
              </Typography>
            </a>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 1 }}>
            <a
              href="https://ayiro.ai/terms-of-use.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#855555' }}
            >
              <Typography variant="body2">
                Terms of Use
              </Typography>
            </a>
            <Typography variant="body2" sx={{ color: '#855555' }}>|</Typography>
            <a
              href="https://ayiro.ai/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#855555' }}
            >
              <Typography variant="body2">
                Privacy Policy
              </Typography>
            </a>
          </Box>
        </Box>
        

        {/* "All Rights Reserved" text below the Contact Us box */}
        <Box
          width="100%"
          sx={{
            textAlign: 'center',
            mt: 2, // Add some margin above the text
          }}
        >
          <Typography variant="body2" sx={{ color: '#855555' }}>
            © 2024 Ayiro. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
