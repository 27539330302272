import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

function RewardsTnC() {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Rewards Program Terms and Conditions
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          Effective Date: 03 September, 2024
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: '1rem', mb: 2 }}>
          By using this application, you agree to the terms and conditions. Please review the terms and conditions before proceeding.
        </Typography>

        <Typography variant="body2" paragraph>
          1. We reserve the right to modify, suspend, or terminate the rewards program at any time without notice.
        </Typography>
        <Typography variant="body2" paragraph>
          2. To participate in the rewards program, you must sign in with your Google or Facebook account.
        </Typography>
        <Typography variant="body2" paragraph>
          3. Anonymous contributions are not eligible for rewards.
        </Typography>
        <Typography variant="body2" paragraph>
          4. Rewards and the cash value of rewards are subject to change without notice. Currently, 1 reward point equals ₹1.
        </Typography>
        <Typography variant="body2" paragraph>
          5. Only one redemption request is allowed in a period of 24 hours.
        </Typography>
        <Typography variant="body2" paragraph>
          6. At any point, a minimum of 100 reward points are needed to activate Redemption.
        </Typography>
        <Typography variant="body2" paragraph>
          7. Currently, we only support payments via UPI. By participating in the rewards program, you agree to provide accurate payment information (UPI ID).
        </Typography>
        <Typography variant="body2" paragraph>
          8. We are not responsible for inaccurate or incomplete payment information provided by the user.
        </Typography>
        <Typography variant="body2" paragraph>
          9. We validate the voice recording provided by the user with the text shown at the time of recording. If they do not match, that recording will be invalid and will not account for reward points.
        </Typography>
        <Typography variant="body2" paragraph>
          10. Multiple invalid recordings will disallow the user from using the app any further.
        </Typography>
        <Typography variant="body2" paragraph>
          11. There is no limit to the number of contributions you can make. However, we reserve the right to limit the number of rewards one person can accumulate.
        </Typography>
        <Typography variant="body2" paragraph>
          12. It may take up to 24 hours for payment completion after a reward redemption request is received. Please contact us at <Link href="mailto:support@ayiro.ai">support@ayiro.ai</Link> or +91-8867439696 if the money is not received by that time.
        </Typography>
        <Typography variant="body2" paragraph>
          13. We have the utmost respect for your privacy. We do not share your personal information with any third party. Please review our Privacy Policy for more information.
        </Typography>
      </Box>
    </Container>
  );
}

export default RewardsTnC;
